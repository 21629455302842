import React from 'react'
import PropTypes from 'prop-types'
import { FlowMain } from './index.style'

const Flow = ({ scale, children }) => (
  <FlowMain spaceScale={scale}>{children}</FlowMain>
)

Flow.propTypes = {
  scale: PropTypes.number,
  children: PropTypes.node,
}

Flow.defaultProps = {
  scale: 1.2,
}

export default Flow
