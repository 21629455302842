import React from 'react'
import Seo from '../components/Seo'
import Container from '../components/Container'
import Grid from '../components/Grid'
import GridItem from '../components/GridItem'
import {
  Super,
  Heading1,
  Heading2,
  Heading3,
  Title,
  TitleSmall,
  TextBodyBold,
  TextBody,
} from '../components/TextStyles'
import Spacer from '../components/Spacer'
import Flow from '../components/Flow'
import ScrollSection from '../components/ScrollSection'
import AnimateSplitText from '../components/animation/AnimateSplitText'

const IndexPage = () => {
  return (
    <>
      <Seo title="Style Guide" />

      <ScrollSection>
        <Container>
          <Grid>
            <GridItem tabletL={8} tabletLStart={4}>
              <Spacer size={[120, 260]} />

              <Flow>
                <Super>
                  <AnimateSplitText>Super</AnimateSplitText>
                </Super>

                <Heading1>
                  <AnimateSplitText>Heading 1</AnimateSplitText>
                </Heading1>

                <Heading2>
                  <AnimateSplitText>Heading 2</AnimateSplitText>
                </Heading2>

                <Heading3>
                  <AnimateSplitText>Heading 3</AnimateSplitText>
                </Heading3>

                <Title>
                  <AnimateSplitText>Title</AnimateSplitText>
                </Title>

                <TitleSmall>
                  <AnimateSplitText>Title Small</AnimateSplitText>
                </TitleSmall>

                <TextBodyBold>
                  <AnimateSplitText>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Culpa error odio minima nemo dolor repellendus aliquam earum
                    hic, ipsum magnam, vitae consequuntur, accusantium facere
                    magni possimus fuga! Odio, a minima?
                  </AnimateSplitText>
                </TextBodyBold>

                <TextBody>
                  <AnimateSplitText>
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                    Itaque labore odit vitae odio voluptatem non reiciendis
                    animi dolorum, cum minima corporis alias error assumenda.
                    Dolorum repudiandae eius exercitationem. Iste, dolore.
                  </AnimateSplitText>
                </TextBody>
              </Flow>

              <Spacer size={[120, 360]} />
            </GridItem>
          </Grid>
        </Container>
      </ScrollSection>
    </>
  )
}

export default IndexPage
